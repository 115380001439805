body,
html {
  background: transparent !important;
  font-family: 'Inter', 'Proxima', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 170px;
}

#root {
  position: relative;
}

a {
  color: #000;
}

hr {
  color: #000;
}

/* Global bootstrap styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.form-control {
  border: 1px solid #ffb41d;
}

.w20 {
  width: 20% !important;
}

.w30 {
  width: 30% !important;
}

.w15 {
  width: 15% !important;
}

.w45 {
  width: 45% !important;
}

.w60 {
  width: 60% !important;
}

.mainCol {
  height: calc(97vh - 44px);
  overflow-y: scroll;
  z-index: 500;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  font-size: 18px;
}

.withBoxShadow {
  box-shadow: 1px 4px 10px 2px rgba(0, 0, 0, 0.25);
}

.mainCol.fullscreen {
  padding: 0;
}

.accordion-item {
  border: 0 !important;
}

.accordion-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accordion-button {
  background: none !important;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding: 0;
  border-bottom: 2px solid #000;
  border-top: 0px;
}

.accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.btn-primary {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 100%;
}

.hiddenBtn {
  border: none;
  background: none;
  padding: 0;
}

.tooltip-inner {
  color: #fff;
  background-color: #111729;
  text-align: left;
}

.tooltip {
  opacity: 1 !important;
}

[x-placement="top"] .tooltip-arrow::before {
  border-top-color: #111729 !important;
}

[x-placement="bottom"] .tooltip-arrow::before {
  border-bottom-color: #111729 !important;
}

[x-placement="right"] .tooltip-arrow::before {
  border-right-color: #111729 !important;
}

[x-placement="left"] .tooltip-arrow::before {
  border-left-color: #111729 !important;
}

/* End global bootstrap styles */

h1 {
  line-height: 32px;
  font-size: 26px;
  font-weight: 500;
  margin: 10px 0px;
}

h2 {
  font-family: 'Inter', 'Proxima', sans-serif;
  font-size: 18px;
}

.subscriptionPlan {
  display: flex;
  color: #000;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.921px;
  border-radius: 8px;
  border: 3px solid #ffb41d;
  background: #f8f9fb;
}

.subscriptionPlan.active {
  color: #fff;
  border: 3px solid #2d47f3;
  background: #6f9fff;
}

.subscriptionPlanName {
  text-transform: uppercase;
  font-size: 28.808px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 43.212px */
}

.panel {
  background-color: #f8f9fb;
  padding: 12px;
  position: relative;
}

.h100 {
  height: 100%;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clear {
  clear: both;
}

.col {
  position: relative;
}

.listingContainer {
  color: #74777c;
  border-color: #ebebfb !important;
}

.listingContainer.selected {
  border-color: #ff6058 !important;
  background-color: rgba(66, 134, 247, 0.1);
}

.listingContainer .listingName {
  color: #1e222b;
}

/* .estimationRow > div {
  color: #656f78;
} */

.estimationRow>div:nth-child(2) {
  color: #1a1a1a;
}

/* .estimationRow span {
  color: #656f78;
} */

.sidebarCol {
  position: relative;
  flex: 0 0 330px;
}

.sidebarCol .listingContainer {
  color: #fff;
  border-color: #383d53 !important;
}

.sidebarCol .listingContainer .listingProps span {
  color: rgba(255, 255, 255, 0.65);
}

.sidebarCol .listingContainer .listingName {
  color: #fff;
}

.sidebarCol .estimationRow>div {
  color: #d4d4f9;
}

.sidebarCol .estimationRow span {
  color: #d4d4f9;
}

.sidebarCol .estimationRow>div:nth-child(2),
.sidebarCol .estimationRow>div:nth-child(2) span {
  color: #fff;
}

.sidebarCol .estimationsTitle {
  font-size: 18px;
  color: #d4d4f9;
}

.setupImages {
  width: 45%;
  border: solid;
  border-radius: 8px;
  margin-bottom: 20px;
}

.centeredContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  text-align: left !important;
}

.alignRight {
  text-align: right !important;
}

.footer {
  text-align: center;
  font-size: 10px;
}

.fixedFooter {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 24px);
}

.repAgreement {
  /* text-align: left; */
  padding-right: 20px;
}

.pagination {
  height: 30px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination li {
  background: none;
  border: none;
  color: #000;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding: 0px 8px;
}

.pagination li.selected,
.pagination li:hover {
  color: #000;
  background: none;
  border: none;
  text-decoration: underline;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle {
  background: none !important;
  border: none;
  font-size: 18px;
  color: #000 !important;
  font-weight: 600;
  width: 100%;
}

.dropdown-toggle:hover {
  background: none;
}

.accountDropdown .dropdown-toggle {
  position: absolute;
  right: 0;
  width: auto;
}

.dropdown-menu {
  background-color: #393e53 !important;
}

.dropdown-item,
.dropdown-item:hover {
  color: #fff !important;
  font-size: 14px;
  background: none !important;
}

.dropdown-item:hover {
  text-decoration: underline;
}

.dropdown-item svg {
  float: right;
  width: 20px;
  height: 20px;
  color: #c8c8ec;
}

.upgradePlanBtn {
  border: 2px solid #ffb41d !important;
  background: #fff !important;
  color: #000 !important;
}

.downgradePlanBtn {
  background-color: #ff6058 !important;
  border-color: #ff0000 !important;
}

.moduleBtnDisabled {
  opacity: 0.5;
  filter: grayscale(100%);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.sliderWrapper {
  background: #fff;
  border-radius: 8px;
  padding: 9px;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 10px;
  border: 1px solid #ffb41d;
}

.sliderWrapper .priceLabel {
  margin: 0 10px;
}

.multi-range-slider {
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.multi-range-slider .ruler {
  display: none;
}

.multi-range-slider .bar-left,
.multi-range-slider .bar-right {
  box-shadow: none;
  background: #625f57;
  height: 3px;
  padding: 0;
}

.multi-range-slider .bar-inner {
  background: #625f57;
  border: none;
  box-shadow: none;
}

.multi-range-slider .thumb::before {
  box-shadow: none;
  border: none;
  width: 18px;
  height: 18px;
  background: #000;
  margin: -8px 0px;
}

.multi-range-slider .thumb-right::before {
  margin-left: -16px !important;
}

.multi-range-slider .thumb .caption,
.multi-range-slider .thumb .caption * {
  display: none;
}

.multi-range-slider .labels {
  display: none;
}

.externalLinks img {
  /* opacity: 0.5;
  filter: grayscale(100%); */
}

.externalLinks img:hover {
  filter: grayscale(0%);
  opacity: 0.85;
}

.autocomplete-container {
  margin-left: 10px;
  margin-top: 10px;
}

.activePlan {
  color: #3eaf3f;
  font-size: 14px;
}

.checkoutTable th {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.checkoutProduct {
  color: #333 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.checkoutProduct span {
  color: #333 !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
  display: block;
  padding-left: 5px;
}

.checkoutProductWeb {
  color: #333 !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.checkoutProductWeb span {
  color: #333 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
  display: block;
  padding-left: 5px;
}

.checkoutPrice {
  color: rgba(51, 51, 51, 0.5) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.checkoutPrice span {
  color: rgba(51, 51, 51, 0.5);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
  display: block;
}

.checkoutPriceWeb {
  color: rgba(51, 51, 51, 0.5) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.checkoutPriceWeb span {
  color: rgba(51, 51, 51, 0.5);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
  display: block;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  border: 2px solid red;
  z-index: 1051;
}

.listing_contact_web {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.sidebarCTA {
  margin-top: 45px;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  align-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.basketTable {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.slick-prev,
.slick-next {
  z-index: 100;
}

.slick-next {
  right: 10px;
}

.slick-prev {
  left: 10px;
}

.react-simple-image-viewer__close {
  opacity: 80%;
}

.react-simple-image-viewer__modal {
  margin-top: 40px;
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  filter: invert(1);
  /* Apply invert filter on hover */
}